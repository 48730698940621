import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

import './numberStep.css';
import { StepsContext } from '../../contexts/stepsContext';

const NumberStep = ({number}) => {
    const {data: {play, playSrc}, toggleGameNumber, togglePlay, setSrc} = useContext(StepsContext);
    const comp = useRef();

    useEffect(() => {
        if (number === 1 && !play && playSrc === '') {
            setSrc('/vanga.mp3');
            togglePlay(true);
        }

        if (number === 2 && !play && playSrc === '') {
            setSrc('/calypso.mp3');
            togglePlay(true);
        }

        if (number === 3 && !play && playSrc === '') {
            setSrc('/breton.mp3');
            togglePlay(true);
        }
    }, [play, playSrc, number]);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.timeline()
            .fromTo('.rect', {
                translateX: (window.innerWidth < window.innerHeight ? window.innerHeight / 2 : window.innerWidth) + 50,
                rotate: 0
            }, {
                translateX: 0,
                rotate: -720 * 2,
                duration: 2
            })
            .to('.rect', {
                scale: 0.7,
                delay: 0.15
            })
            .to('.rect', {
                scaleX: window.innerWidth / (50 - 15),
                scaleY: window.innerHeight / (50 - 15),
                delay: 0.15,
                duration: 1
            })
            .fromTo('span', {
                opacity: 0,
                translateY: window.innerHeight / 2 + 80
            }, {
                opacity: 1,
                translateY: 0
            })
            .to('span',  {
                translateY: -window.innerHeight / 2 - 80,
                opacity: 0,
                delay: 3
            })
            .to('span', {opacity: 0})
            .to('.rect', {scale: 0.7})
            .to('.rect', {scale: 1})
            .to('.rect', {
                translateX: -window.innerWidth,
                rotate: -720 * 4,
                duration: 1.5,
                delay: 0.15,
                onComplete: () => {
                    togglePlay(false);
                    setSrc('');
                    toggleGameNumber();
                }
            });
        })

        return () => ctx.revert();
    });
    
    return (
        <div className="number-steps" ref={comp}>
            <div className="rect" />
            <span>0{number}</span>
        </div>
    )

}

export default NumberStep;