import { useState, useContext, useEffect } from "react";
import { StepsContext } from "../../contexts/stepsContext";

const useAudio = () => {
    const [audioRef, setAudioRef] = useState(null);
    const {data: {play}} = useContext(StepsContext);

    const toggle = (force) => {
        if (audioRef) {
            audioRef.load();

            if (typeof force === 'boolean') {
                if (force) {
                    audioRef.play();
                } else {
                    audioRef.pause();
                }
            } else {
                if (play) {
                    audioRef.play();
                } else {
                    audioRef.pause();
                }
            }
        }
    }

    useEffect(() => {
        if (audioRef) {
            audioRef.load();
        }
    }, [audioRef]);

    return [audioRef, toggle, setAudioRef];
}

export default useAudio;