import React, { useCallback, useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import gsap from "gsap";

import { KANAS } from "../../constants/kanas";

import ShadowKana from "../../components/shadowKana/shadowKana";
import Kana from "../../components/kana/kana";
import Kanagrid from "../../components/table/kanagrid";
import Chevron from "../../components/chevron/chevron";

import { StepsContext } from "../../contexts/stepsContext";
import './rebus.css';

const Rebus = () => {
    const {changeState, toggleGameNumber, incrementGameNumber, togglePlay, setSrc} = useContext(StepsContext);

    const [showRules, setShowRules] = useState(true);
    const [kanaExample] = useState(KANAS.find((k) => k.romaji === 'RA'));
    const [imgExample] = useState('rat.jpg');
    const [clicked, setClicked] = useState(false);
    const [activeIndex, setActiveIndex] = useState(1);
    const [kanaSelected, setKanaSelected] = useState(undefined);
    const [openKanagrid, setOpenKanagrid] = useState(false);
    const [rebusState, setRebusState] = useState([ 
        {
            state: 1,
            imgName: 'wow_la_ville.jpg',
            kana: KANAS.find((k) => k.romaji === 'RU'),
            valid: false
        }, {
            state: 2,
            imgName: 'qui_vole_un_oeuf.jpg',
            kana: KANAS.find((k) => k.romaji === 'NI'),
            valid: false
        }, {
            state: 3,
            imgName: 'jamel_de_bouse.jpg',
            kana: KANAS.find((k) => k.romaji === 'TA'),
            valid: false
        }, {
            state: 4,
            imgName: 'SLURP_LE_CAFE.jpg',
            kana: KANAS.find((k) => k.romaji === 'BU'),
            valid: false
        }, {
            state: 5,
            imgName: 'cheval.jpg',
            kana: KANAS.find((k) => k.romaji === 'U'),
            valid: false
        }
    ]);
    

    const handleStart = (e) => {
        e.preventDefault();
        
        gsap.to('.fade', {
            opacity: 0,
            translateY: -20,
            duration: 0.7,
            stagger: 0.3,
            onComplete: function() {
                setShowRules(false);
            }
        })
    };

    const toggleOpenKanagrid = useCallback((e) => {
        if (!clicked) {
            setClicked(true);
        }
        
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setOpenKanagrid(!openKanagrid);
    }, [openKanagrid, setOpenKanagrid]);

    const ctaKana = useCallback((kana) => {
        setKanaSelected(kana);
    }, [setKanaSelected, setOpenKanagrid, openKanagrid]);

    const handleValidate = useCallback((e) => {
        e.preventDefault();

        if (!kanaSelected || kanaSelected && kanaSelected.id !== rebusState[activeIndex - 1].kana.id) {
            setSrc('/nani.mp3');
            togglePlay(true);
            
            gsap.timeline().to('.validate', {translateX: -2, duration: 0.2})
            .to('.validate', {translateX: 2, duration: 0.1})
            .to('.validate', {translateX: -2, duration: 0.1})
            .to('.validate', {translateX: 2, duration: 0.1})
            .to('.validate', {translateX: -2, duration: 0.1})
            .to('.validate', {translateX: 2, duration: 0.1})
            .to('.validate', {translateX: -2, duration: 0.1})
            .to('.validate', {
                translateX: 2, 
                duration: 0.1,
                onComplete: () => {
                    setSrc('');
                    togglePlay(false);
                }
            });
        } else {
            gsap.timeline().to('.validate', {
                translateY: 10,
                duration: 0.15
            })
            .to('.validate', {
                translateY: 0,
                onComplete: () => {
                    setSrc('/dattebayo.mp3');
                    togglePlay(true);
                    
                    if (activeIndex === 5) {
                        gsap.timeline().to('.fade-after', {
                            translateY: '-20px',
                            opacity: 0,
                            duration: 1,
                            onComplete: () => {
                                toggleGameNumber();
                                changeState(4);
                                incrementGameNumber();
                                setSrc('');
                                togglePlay(false);
                            }
                        });
                    } else {
                        gsap.timeline().to(`.rebus-${activeIndex}`, {
                            translateX: '-150%',
                            duration: 1,
                            delay: 0.4,
                            onComplete: () => {
                                setActiveIndex((index) => index + 1);
                                setSrc('');
                                togglePlay(false);
                            }
                        });
                    }
                }
            });
        }
    }, [activeIndex, kanaSelected, rebusState, setActiveIndex, setRebusState]);

    const showGame = useCallback(() => {        
        for (const rebus of rebusState) {
            if (activeIndex !== rebus.state) {
                gsap.set(`.rebus-${rebus.state}`, {display: 'none'});
            } else {
                gsap.set(`.rebus-${rebus.state}`, {display: 'block', translateX: '100vw'});
                gsap.to(`.rebus-${rebus.state}`, {translateX: 0, duration: 1, delay: 0.4});
            }
        }

        gsap.set('.fade', {display: 'none'});
        gsap.set('.fade-after', {display: 'block'});
        gsap.fromTo('.fade-after',  {
            opacity: 0,
            translateY: 20 
        }, {
            opacity: 1,
            translateY: -20,
            duration: 0.7
        });
    });

    const comp = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if (showRules) {
                gsap.set('.fade-after', {opacity: 0, display: 'none'});
                gsap.fromTo('.fade', {
                    opacity: 0,
                    translateY: 20 
                }, {
                    opacity: 1,
                    translateY: -20,
                    duration: 0.7,
                    stagger: 0.3
                });
            } else {
                showGame();
            }
        }, comp);

        return () => ctx.revert();
    }, []);

    useEffect(() => {
        if (!showRules) {
            showGame();
        }
    }, [showRules]);

    useEffect(() => {
        if (openKanagrid) {
            setOpenKanagrid(false);
        }
    }, [kanaSelected])

    useEffect(() => {
        if (activeIndex !== 1) {
            const newRebusState = [...rebusState];
            newRebusState[activeIndex - 2].valid = true;
            setRebusState(newRebusState);
        }
    }, [activeIndex]);

    useEffect(() => {
        for (const rebus of rebusState) {
            if (activeIndex !== rebus.state) {
                gsap.set(`.rebus-${rebus.state}`, {display: 'none'});
            } else {
                gsap.set(`.rebus-${rebus.state}`, {display: 'block', translateX: '150%'});
                gsap.to(`.rebus-${rebus.state}`, {translateX: 0, duration: 1, delay: 0.4});
            }
        }

        setKanaSelected(undefined);
    }, [rebusState]);

    const renderRules = useCallback(() => {
        return (
            <div>
                <h2 className="stylized-text bigger fade">Rébus</h2>
                <div>
                    <p className="stylized-text fade">Trouve le caractère japonais lié à l'image dans le tableau de caractères katakana</p>
                </div>
                <div className="rebus fade">
                    <p className="stylized-text">Exemple</p>
                    <figure>
                        <img src={imgExample} alt="image d'exemple" />
                    </figure>
                    <div className="centered" style={{marginTop: "20px", marginBottom: "20px"}}>
                        <p className="stylized-text">Le caractère est</p>
                        <div style={{marginLeft: "15px"}}><Kana kana={kanaExample} /></div>
                    </div>
                </div>
                <div className="centered">
                    <a href="#" className="button fade" onClick={handleStart}>Commencer</a>
                </div>
            </div>
        );
    });

    const renderSteps = useCallback(() => {
        return rebusState.map((rebus) => {
            return <div className={`rebus rebus-${rebus.state}`} key={`rebus-${rebus.state}`}>
                <div className="rebus__state">0{rebus.state}/0{rebusState.length}</div>
                <figure onClick={toggleOpenKanagrid}>
                    <img src={`/${rebus.imgName}`} alt={`image du rebus ${rebus.state}`} />
                </figure>
            </div>
        });
    }, [rebusState, activeIndex]);

    const renderGame = useCallback(() => {
        return <div className="fade-after">
            {renderSteps()}
            <div className="centered rebus__cta">
                <div>
                    {kanaSelected ? <Kana kana={kanaSelected} cta={toggleOpenKanagrid} /> : <ShadowKana cta={toggleOpenKanagrid} />}
                </div>
                <a href="#" className="button validate" onClick={handleValidate}>Valider</a>
            </div>
        </div>
    }, [activeIndex, rebusState, kanaSelected]);

    return <div ref={comp}>
        {renderRules()}
        {renderGame()}
        <Kanagrid isOpen={openKanagrid} cta={ctaKana} />
        <a 
            href="#" 
            className={`rebus__show-grid ${clicked ? '' : 'beat'} ${openKanagrid ? 'open' : ''}`}
            onClick={(e) => {
                toggleOpenKanagrid(e);
            }}
        >
            <Chevron />
        </a>
    </div>
};

export default Rebus;