import React from "react";

import './shadowKana.css';

const ShadowKana = ({cta}) => {
    const handle = () => {
        if (cta) {
            cta();
        }
    }

    return <div className="shadow-kana" onClick={handle} />;
}

export default ShadowKana;