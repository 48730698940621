export const KANAS = [
    {
        id: 1,
        hiragana: 'あ',
        katakana: 'ア',
        romaji: 'A',
        type: 'gojuon',
        vowel: 'a',
        consonant: ''
    },
    {
        id: 2,
        hiragana: 'い',
        katakana: 'イ',
        romaji: 'I',
        type: 'gojuon',
        vowel: 'i',
        consonant: ''
    },
    {
        id: 3,
        hiragana: 'う',
        katakana: 'ウ',
        romaji: 'U',
        type: 'gojuon',
        vowel: 'u',
        consonant: ''
    },
    {
        id: 4,
        hiragana: 'え',
        katakana: 'エ',
        romaji: 'E',
        type: 'gojuon',
        vowel: 'e',
        consonant: ''
    },
    {
        id: 5,
        hiragana: 'え',
        katakana: 'オ',
        romaji: 'O',
        type: 'gojuon',
        vowel: 'o',
        consonant: ''
    },
    {
        id: 6,
        hiragana: 'か',
        katakana: 'カ',
        romaji: 'KA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'k'
    },
    {
        id: 7,
        hiragana: 'き',
        katakana: 'キ',
        romaji: 'KI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 'k'
    },
    {
        id: 8,
        hiragana: 'く',
        katakana: 'ク',
        romaji: 'KU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 'k'
    },
    {
        id: 9,
        hiragana: 'け',
        katakana: 'ケ',
        romaji: 'KE',
        type: 'gojuon',
        vowel: 'e',
        consonant: 'k'
    },
    {
        id: 10,
        hiragana: 'こ',
        katakana: 'コ',
        romaji: 'KO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'k'
    },
    {
        id: 11,
        hiragana: 'さ',
        katakana: 'サ',
        romaji: 'SA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 's'
    },
    {
        id: 12,
        hiragana: 'し',
        katakana: 'シ',
        romaji: 'SHI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 's'
    },
    {
        id: 13,
        hiragana: 'す',
        katakana: 'ス',
        romaji: 'SU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 's'
    },
    {
        id: 14,
        hiragana: 'せ',
        katakana: 'セ',
        romaji: 'SE',
        type: 'gojuon',
        vowel: 'e',
        consonant: 's'
    },
    {
        id: 15,
        hiragana: 'そ',
        katakana: 'ソ',
        romaji: 'SO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 's'
    },
    {
        id: 16,
        hiragana: 'た',
        katakana: 'タ',
        romaji: 'TA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 't'
    },
    {
        id: 17,
        hiragana: 'ち',
        katakana: 'チ',
        romaji: 'CHI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 't'
    },
    {
        id: 18,
        hiragana: 'つ ',
        katakana: 'ツ',
        romaji: 'TSU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 't'
    },
    {
        id: 19,
        hiragana: 'て',
        katakana: 'テ',
        romaji: 'TE',
        type: 'gojuon',
        vowel: 'e',
        consonant: 't'
    },
    {
        id: 20,
        hiragana: 'あ',
        katakana: 'ト',
        romaji: 'TO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 't'
    },
    {
        id: 21,
        hiragana: 'な',
        katakana: 'ナ',
        romaji: 'NA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'n'        
    },
    {
        id: 22,
        hiragana: 'に',
        katakana: 'ニ',
        romaji: 'NI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 'n'        
    },
    {
        id: 23,
        hiragana: 'ぬ',
        katakana: 'ヌ',
        romaji: 'NU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 'n'        
    },
    {
        id: 24,
        hiragana: 'ね',
        katakana: 'ネ',
        romaji: 'NE',
        type: 'gojuon',
        vowel: 'e',
        consonant: 'n'        
    },
    {
        id: 25,
        hiragana: 'の',
        katakana: 'ノ',
        romaji: 'NO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'n'        
    },
    {
        id: 26,
        hiragana: 'は',
        katakana: 'ハ',
        romaji: 'HA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'h'
    },
    {
        id: 27,
        hiragana: 'ひ',
        katakana: 'ヒ',
        romaji: 'HI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 'h'
    },
    {
        id: 28,
        hiragana: 'ふ ',
        katakana: 'フ',
        romaji: 'FU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 'h'
    },
    {
        id: 29,
        hiragana: 'へ',
        katakana: 'ヘ',
        romaji: 'HE',
        type: 'gojuon',
        vowel: 'e',
        consonant: 'h'
    },
    {
        id: 30,
        hiragana: 'ほ',
        katakana: 'ホ',
        romaji: 'HO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'h'
    },
    {
        id: 31,
        hiragana: 'ま',
        katakana: 'マ',
        romaji: 'MA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'm'
    },
    {
        id: 32,
        hiragana: 'み',
        katakana: 'ミ',
        romaji: 'MI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 'm'
    },
    {
        id: 33,
        hiragana: 'む',
        katakana: 'ム',
        romaji: 'MU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 'm'
    },
    {
        id: 34,
        hiragana: 'め',
        katakana: 'メ',
        romaji: 'ME',
        type: 'gojuon',
        vowel: 'e',
        consonant: 'm'
    },
    {
        id: 35,
        hiragana: 'も',
        katakana: 'モ',
        romaji: 'MO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'm'
    },
    {
        id: 36,
        hiragana: 'や',
        katakana: 'ヤ',
        romaji: 'YA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'y'
    },
    {
        id: 37,
        hiragana: 'ゆ',
        katakana: 'ユ',
        romaji: 'YU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 'y'
    },
    {
        id: 38,
        hiragana: 'よ',
        katakana: 'ヨ',
        romaji: 'YO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'y'
    },
    {
        id: 39,
        hiragana: 'ら',
        katakana: 'ラ',
        romaji: 'RA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'r'
    },
    {
        id: 40,
        hiragana: 'り',
        katakana: 'リ',
        romaji: 'RI',
        type: 'gojuon',
        vowel: 'i',
        consonant: 'r'
    },
    {
        id: 41,
        hiragana: 'る',
        katakana: 'ル',
        romaji: 'RU',
        type: 'gojuon',
        vowel: 'u',
        consonant: 'r'
    },
    {
        id: 42,
        hiragana: 'れ',
        katakana: 'レ',
        romaji: 'RE',
        type: 'gojuon',
        vowel: 'e',
        consonant: 'r'
    },
    {
        id: 43,
        hiragana: 'ろ',
        katakana: 'ロ',
        romaji: 'RO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'r'
    },
    {
        id: 44,
        hiragana: 'わ',
        katakana: 'ワ',
        romaji: 'WA',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'w'
    },
    {
        id: 45,
        hiragana: 'を',
        katakana: 'ヲ',
        romaji: 'WO',
        type: 'gojuon',
        vowel: 'o',
        consonant: 'w'
    },
    {
        id: 46,
        hiragana: 'ん',
        katakana: 'ン',
        romaji: 'N',
        type: 'gojuon',
        vowel: 'a',
        consonant: 'nn'
    },
    {
        id: 47,
        hiragana: 'が',
        katakana: 'ガ',
        romaji: 'GA',
        type: 'dakuon',
        vowel: 'a',
        consonant: 'g'
    },
    {
        id: 48,
        hiragana: 'ぎ',
        katakana: 'ギ',
        romaji: 'GI',
        type: 'dakuon',
        vowel: 'i',
        consonant: 'g'
    },
    {
        id: 49,
        hiragana: 'ぐ',
        katakana: 'グ',
        romaji: 'GU',
        type: 'dakuon',
        vowel: 'u',
        consonant: 'g'
    },
    {
        id: 50,
        hiragana: 'げ',
        katakana: 'ゲ',
        romaji: 'GE',
        type: 'dakuon',
        vowel: 'e',
        consonant: 'g'
    },
    {
        id: 51,
        hiragana: 'ご',
        katakana: 'ゴ',
        romaji: 'GO',
        type: 'dakuon',
        vowel: 'o',
        consonant: 'g'
    },
    {
        id: 52,
        hiragana: 'ざ',
        katakana: 'ザ',
        romaji: 'ZA',
        type: 'dakuon',
        vowel: 'a',
        consonant: 'z'
    },
    {
        id: 53,
        hiragana: 'じ',
        katakana: 'ジ',
        romaji: 'JI',
        type: 'dakuon',
        vowel: 'i',
        consonant: 'z'
    },
    {
        id: 54,
        hiragana: 'ず',
        katakana: 'ズ',
        romaji: 'ZU',
        type: 'dakuon',
        vowel: 'u',
        consonant: 'z'
    },
    {
        id: 55,
        hiragana: 'ぜ',
        katakana: 'ゼ',
        romaji: 'ZE',
        type: 'dakuon',
        vowel: 'e',
        consonant: 'z'
    },
    {
        id: 56,
        hiragana: 'ぞ',
        katakana: 'ゾ',
        romaji: 'ZO',
        type: 'dakuon',
        vowel: 'o',
        consonant: 'z'
    },
    {
        id: 57,
        hiragana: 'だ',
        katakana: 'ダ',
        romaji: 'DA',
        type: 'dakuon',
        vowel: 'a',
        consonant: 'd'
    },
    {
        id: 58,
        hiragana: 'ぢ',
        katakana: 'ヂ',
        romaji: 'DI',
        type: 'dakuon',
        vowel: 'i',
        consonant: 'd'
    },
    {
        id: 59,
        hiragana: 'づ',
        katakana: 'ヅ',
        romaji: 'DU',
        type: 'dakuon',
        vowel: 'u',
        consonant: 'd'
    },
    {
        id: 60,
        hiragana: 'で',
        katakana: 'デ',
        romaji: 'DE',
        type: 'dakuon',
        vowel: 'e',
        consonant: 'd'
    },
    {
        id: 61,
        hiragana: 'ど',
        katakana: 'ド',
        romaji: 'DO',
        type: 'dakuon',
        vowel: 'o',
        consonant: 'd'
    },
    {
        id: 62,
        hiragana: 'ば',
        katakana: 'バ',
        romaji: 'BA',
        type: 'dakuon',
        vowel: 'a',
        consonant: 'b'
    },
    {
        id: 63,
        hiragana: 'び',
        katakana: 'ビ',
        romaji: 'BI',
        type: 'dakuon',
        vowel: 'i',
        consonant: 'b'
    },
    {
        id: 64,
        hiragana: 'ぶ',
        katakana: 'ブ',
        romaji: 'BU',
        type: 'dakuon',
        vowel: 'u',
        consonant: 'b'
    },
    {
        id: 65,
        hiragana: 'べ',
        katakana: 'ベ',
        romaji: 'BE',
        type: 'dakuon',
        vowel: 'e',
        consonant: 'b'
    },
    {
        id: 66,
        hiragana: 'ぼ',
        katakana: 'ボ',
        romaji: 'BO',
        type: 'dakuon',
        vowel: 'o',
        consonant: 'b'
    },
    {
        id: 67,
        hiragana: 'ぱ',
        katakana: 'パ',
        romaji: 'PA',
        type: 'dakuon',
        vowel: 'a',
        consonant: 'p'
    },
    {
        id: 68,
        hiragana: 'ぴ',
        katakana: 'ピ',
        romaji: 'PI',
        type: 'dakuon',
        vowel: 'i',
        consonant: 'p'
    },
    {
        id: 69,
        hiragana: 'ぷ',
        katakana: 'プ',
        romaji: 'PU',
        type: 'dakuon',
        vowel: 'u',
        consonant: 'p'
    },
    {
        id: 70,
        hiragana: 'ぺ',
        katakana: 'ペ',
        romaji: 'PE',
        type: 'dakuon',
        vowel: 'e',
        consonant: 'p'
    },
    {
        id: 71,
        hiragana: 'ぽ',
        katakana: 'ポ',
        romaji: 'PO',
        type: 'dakuon',
        vowel: 'o',
        consonant: 'p'
    },
    {
        id: 72,
        hiragana: 'きゃ',
        katakana: 'キャ',
        romaji: 'KYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'ky' 
    },
    {
        id: 73,
        hiragana: 'きゅ',
        katakana: 'キュ',
        romaji: 'KYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'ky'
    },
    {
        id: 74,
        hiragana: 'きょ',
        katakana: 'キョ',
        romaji: 'KYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'ky'
    },
    {
        id: 75,
        hiragana: 'ぎゃ',
        katakana: 'ギャ',
        romaji: 'GYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'gy'
    },
    {
        id: 76,
        hiragana: 'ぎゅ',
        katakana: 'ギュ',
        romaji: 'GYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'gy'
    },
    {
        id: 77,
        hiragana: 'ぎょ',
        katakana: 'ギョ',
        romaji: 'GYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'gy'
    },
    {
        id: 78,
        hiragana: 'しゃ',
        katakana: 'シャ',
        romaji: 'SHA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'sh'
    },
    {
        id: 79,
        hiragana: 'しゅ',
        katakana: 'シュ',
        romaji: 'SHU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'sh'
    },
    {
        id: 80,
        hiragana: 'しょ',
        katakana: 'ショ',
        romaji: 'SHO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'sh'
    },
    {
        id: 81,
        hiragana: 'じゃ',
        katakana: 'ジャ',
        romaji: 'JA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'j'
    },
    {
        id: 82,
        hiragana: 'じゅ',
        katakana: 'ジュ',
        romaji: 'JU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'j'
    },
    {
        id: 83,
        hiragana: 'じょ',
        katakana: 'ジョ',
        romaji: 'JO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'j'
    },
    {
        id: 84,
        hiragana: 'ちゃ',
        katakana: 'チャ',
        romaji: 'CHA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'ch' 
    },
    {
        id: 85,
        hiragana: 'ちゅ',
        katakana: 'チュ',
        romaji: 'CHU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'ch'
    },
    {
        id: 86,
        hiragana: 'ちょ',
        katakana: 'チョ',
        romaji: 'CHO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'ch'
    },
    {
        id: 87,
        hiragana: 'にゃ',
        katakana: 'ニャ',
        romaji: 'NYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'ny' 
    },
    {
        id: 88,
        hiragana: 'にゅ',
        katakana: 'ニュ',
        romaji: 'NYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'ny'
    },
    {
        id: 89,
        hiragana: 'にょ',
        katakana: 'ニョ',
        romaji: 'NYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'ny'
    },
    {
        id: 90,
        hiragana: 'ひゃ',
        katakana: 'ヒャ',
        romaji: 'HYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'hy'
    },
    {
        id: 91,
        hiragana: 'ひゅ',
        katakana: 'ヒュ',
        romaji: 'HYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'hy'
    },
    {
        id: 92,
        hiragana: 'ひょ',
        katakana: 'ヒョ',
        romaji: 'HYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'hy'
    },
    {
        id: 93,
        hiragana: 'びゃ',
        katakana: 'ビャ',
        romaji: 'BYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'by'
    },
    {
        id: 94,
        hiragana: 'びゅ',
        katakana: 'ビュ',
        romaji: 'BYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'by'
    },
    {
        id: 95,
        hiragana: 'びょ',
        katakana: 'ビョ',
        romaji: 'BYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'by'
    },
    {
        id: 96,
        hiragana: 'ぴゃ',
        katakana: 'ピャ',
        romaji: 'PYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'py'
    },
    {
        id: 97,
        hiragana: 'ぴゅ',
        katakana: 'ピュ',
        romaji: 'PYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'py'
    },
    {
        id: 98,
        hiragana: 'ぴょ',
        katakana: 'ピョ',
        romaji: 'PYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'py'
    },
    {
        id: 99,
        hiragana: 'みゃ',
        katakana: 'ミャ',
        romaji: 'MYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'my'
    },
    {
        id: 100,
        hiragana: 'みゅ',
        katakana: 'ミュ',
        romaji: 'MYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'my'
    },
    {
        id: 101,
        hiragana: 'みょ',
        katakana: 'ミョ',
        romaji: 'MYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'my'
    },
    {
        id: 102,
        hiragana: 'りゃ',
        katakana: 'リャ',
        romaji: 'RYA',
        type: 'yoon',
        vowel: 'a',
        consonant: 'ry'
    },
    {
        id: 103,
        hiragana: 'りゅ',
        katakana: 'リュ',
        romaji: 'RYU',
        type: 'yoon',
        vowel: 'u',
        consonant: 'ry'
    },
    {
        id: 104,
        hiragana: 'りょ',
        katakana: 'リョ',
        romaji: 'RYO',
        type: 'yoon',
        vowel: 'o',
        consonant: 'ry'
    },
];
