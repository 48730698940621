import React, { useContext, useLayoutEffect, useRef } from "react";
import gsap from "gsap";

import { StepsContext } from "../../contexts/stepsContext";
import './hello.css';

const Hello = () => {
    const {changeState, setSrc, togglePlay} = useContext(StepsContext);

    const handleCta = (e) => {
        e.preventDefault();

        gsap.to('.fade', {
            opacity: 0,
            translateY: -20,
            duration: 0.7,
            stagger: 0.3,
            onComplete: function() {
                changeState(2);

                setSrc('/fantome.mp3');
                togglePlay(true);
            }
        })
    }

    const comp = useRef();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.fromTo('.fade', {
                opacity: 0,
                translateY: 20 
            }, {
                opacity: 1,
                translateY: -20,
                duration: 0.7,
                stagger: 0.3
            })
        }, comp);

        return () => ctx.revert();
    }, []);

    return (
        <div ref={comp}>
            <div className="stylized-text bigger">
                <p className="fade">Holà <span>リリア</span></p>
            </div>
            <div className="stylized-text">
                <p className="fade">Voici un petit jeu d'énigmes créé de toute pièce par mes soins. 
                    Le but sera d'aller au bout de 3 mini-jeux qui t'attendent. 
                    Oseras tu relever le défi ? 😉</p>
                <a className="button fade" href="#" onClick={handleCta}>Continuer</a>
            </div> 
        </div>
    )
}

export default Hello;