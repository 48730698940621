import React from 'react';
import './App.css';
import { StepsContextProvider } from './contexts/stepsContext';
import Main from './components/main/main';

function App() {
  return (
    <StepsContextProvider>
      <div className="app">
        <Main />
      </div>
    </StepsContextProvider>
  );
}

export default App;
