import React, { useContext, useState, useLayoutEffect, useRef, useEffect, useCallback } from "react";
import gsap from "gsap";
import { StepsContext } from "../../contexts/stepsContext";
import { KANAS } from "../../constants/kanas";

import Kana from "../../components/kana/kana";

import './makeWorld.css';

const MakeWorld = () => {
    const {changeState, toggleGameNumber, incrementGameNumber, setSrc, togglePlay} = useContext(StepsContext);

    const [kanas, setKanas] = useState([
        KANAS.find((kana) => kana.romaji === 'RU'),
        KANAS.find((kana) => kana.romaji === 'NI'),
        KANAS.find((kana) => kana.romaji === 'TA'),
        KANAS.find((kana) => kana.romaji === 'BU'),
        KANAS.find((kana) => kana.romaji === 'U'),
    ]);

    const [userChoice, setUserChoice] = useState([
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
    ]);

    const [world] = useState([
        KANAS.find((kana) => kana.romaji === 'BU'),
        KANAS.find((kana) => kana.romaji === 'RU'),
        KANAS.find((kana) => kana.romaji === 'TA'),
        KANAS.find((kana) => kana.romaji === 'NI'),
        KANAS.find((kana) => kana.romaji === 'U'),
    ]);

    const [showRules, setShowRules] = useState(true);

    const handleStart = (e) => {
        e.preventDefault();
        
        gsap.to('.fade', {
            opacity: 0,
            translateY: -20,
            duration: 0.7,
            stagger: 0.3,
            onComplete: function() {
                setShowRules(false);
            }
        })
    } 

    const getClassName = useCallback(() => {
        if (userChoice[0] === undefined) return 'first-choice';
        if (userChoice[1] === undefined) return 'second-choice';
        if (userChoice[2] === undefined) return 'third-choice';
        if (userChoice[3] === undefined) return 'fourth-choice';
        if (userChoice[4] === undefined) return 'fifth-choice';
    }, [userChoice, kanas]);

    const comp = useRef();

    const showGame = () => {
        gsap.set('.fade', {display: 'none'});
        gsap.set('.fade-after', {display: 'block'});
        gsap.fromTo('.fade-after',  {
            opacity: 0,
            translateY: 20 
        }, {
            opacity: 1,
            translateY: -20,
            duration: 0.7
        });
    }

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if (showRules) {
                gsap.set('.fade-after', {opacity: 0, display: 'none'});
                gsap.fromTo('.fade', {
                    opacity: 0,
                    translateY: 20 
                }, {
                    opacity: 1,
                    translateY: -20,
                    duration: 0.7,
                    stagger: 0.3
                });
            } else {
                showGame();
            }
        }, comp);

        return () => ctx.revert();
    }, []);

    useEffect(() => {
        if (!showRules) {
            showGame();
        }
    }, [showRules])

    const renderRules = () => {
        return (
            <div>
                <h2 className="stylized-text bigger fade">Make World</h2>
                <p className="stylized-text fade">Ranges les caractères dans l'ordre afin de créer le mot suggéré (mot en japonais).</p>
                <p className="stylized-text fade">Une fois les caractères rangés, valides ton choix avec le bouton. Bonne chance !</p>
                <div className="centered">
                    <a href="#" className="button fade" onClick={handleStart}>Commencer</a>
                </div>
            </div>
        );
    }

    const renderChoiceZone = () => {
        return kanas.map((_kana, index) => {
            return <div key={`zone-${index}`} className={"kanas-zone__choice"} />
        });
    }

    const handleKana = useCallback((kc) => {
        const index = userChoice.findIndex((u) => u !== undefined && u.id === kc.id);
        const indexUndefined = userChoice.findIndex((u) => u === undefined);
        const kanaIndex = kanas.findIndex((k) => k.id === kc.id);

        const newUserChoice = [...userChoice];
        const newKanas = [...kanas];

        if (index === -1) {
            newUserChoice[indexUndefined] = kc;
            newKanas[kanaIndex].classname = getClassName();
        } else {
            newUserChoice[index] = undefined;
            delete newKanas[kanaIndex].classname
        }

        setUserChoice(newUserChoice);
        setKanas(newKanas);
    }, [userChoice, setUserChoice, kanas, setKanas]);

    const renderKanas = () => {
        return kanas.map((kana) => {
            return <div 
                className={`kanas-zone__holder ${kana.classname ? kana.classname : ''}`}
                key={`kana-question-${kana.id}`}
                onClick={() => handleKana(kana)}
            >
                <Kana kana={kana} />
            </div>
        });
    };

    const handleValidate = useCallback((e) => {
            e.preventDefault();
            
            if (!isChoiceFilled() || !isGoodWord()) {
                setSrc('/nani.mp3');
                togglePlay(true);

                gsap.timeline().to('.validate', {translateX: -2, duration: 0.2})
                .to('.validate', {translateX: 2, duration: 0.1})
                .to('.validate', {translateX: -2, duration: 0.1})
                .to('.validate', {translateX: 2, duration: 0.1})
                .to('.validate', {translateX: -2, duration: 0.1})
                .to('.validate', {translateX: 2, duration: 0.1})
                .to('.validate', {translateX: -2, duration: 0.1})
                .to('.validate', {
                    translateX: 2,
                    duration: 0.1,
                    onComplete: () => {
                        setSrc('');
                        togglePlay(false);
                    }
                });
            }

            if (isChoiceFilled() && !isGoodWord()) {
                const classes = [
                    'first-choice',
                    'second-choice',
                    'third-choice',
                    'fourth-choice',
                    'fifth-choice'
                ];

                const newKanas = [...kanas];

                for (let i = 0; i < userChoice.length; i++) {
                    const u = userChoice[i];
                    const w = world[i];
                    const c = classes[i];
                    const k = newKanas.find((v) => v.id === u.id);

                    if (i === 0) {
                        console.log(u.id === w.id)
                    }

                    if (u.id === w.id) {
                        k.classname = `${c} valid`;
                    } else {
                        k.classname = `${c}`;
                    }
                }

                setKanas(newKanas);
            }

            if (isChoiceFilled() && isGoodWord()) {
                setSrc('/dattebayo.mp3');
                togglePlay(true);

                gsap.timeline().to('.validate', {
                    translateY: 10,
                    duration: 0.15
                })
                .to('.validate', {
                    translateY: 0,
                    onComplete: () => {
                        gsap.timeline().to('.fade-after', {
                            translateY: '-20px',
                            opacity: 0,
                            duration: 1,
                            onComplete: () => {
                                setSrc('');
                                togglePlay(false);
                                toggleGameNumber();
                                changeState(5);
                                incrementGameNumber();
                            }
                        });
                    }
                });
            }   
    }, [userChoice, kanas, setKanas]);

    const isChoiceFilled = useCallback(() => {
        return userChoice[0] !== undefined &&
        userChoice[1] !== undefined &&
        userChoice[2] !== undefined &&
        userChoice[3] !== undefined &&
        userChoice[4] !== undefined;
    }, [userChoice]);

    const isGoodWord = useCallback(() => {
        if (!isChoiceFilled()) {
            return false;
        }

        return userChoice[0].id === world[0].id &&
        userChoice[1].id === world[1].id &&
        userChoice[2].id === world[2].id &&
        userChoice[3].id === world[3].id &&
        userChoice[4].id === world[4].id;
    }, [userChoice, world]);

    const renderGame = () => {
        return <div className="fade-after">
            <div className="kanas-zone">
                <div>
                    {renderChoiceZone()}
                </div>
                <div>
                    {renderKanas()}
                </div>
            </div>
            <div className="centered">
                <a 
                    href="#"
                    className="button validate" 
                    onClick={handleValidate}
                 >Valider</a>
            </div>
        </div>
    }

    return <div ref={comp}>
        {renderRules()}
        {renderGame()}
    </div>
};

export default MakeWorld;