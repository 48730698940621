import React, { useState } from "react";
import { KANAS } from "../../constants/kanas";
import Kana from "../kana/kana";

import './table.css';

const Kanagrid = ({isOpen, cta}) => {
    const [kanas] = useState(KANAS.filter((kana) => kana.type !== 'yoon'));
    const [vowels] = useState(['a', 'i', 'u', 'e', 'o']);
    const [consonants] = useState(['', 'k', 'g', 's', 'z', 't', 'd', 'n', 'h', 'b', 'p', 'y', 'r', 'w', 'nn']);

    const renderKanas = (vowel) => {
        return consonants.map((consonant, index) => {
            const kana = kanas.filter((kana) => kana.consonant === consonant && kana.vowel === vowel)[0];
            return <React.Fragment key={`row-vowel-${consonant}${vowel}-${index}`}>
                {index === 0 && 
                    <div className="grid__vowel">
                        <div>{kana.vowel.toUpperCase()}</div>
                    </div>
                }
                <div className={kana ? '' : 'empty'} >
                    {kana ? <Kana kana={kana} cta={cta} /> : null}
                </div>
            </React.Fragment>;
        });
    }

    const renderGrid = () => {
        if (vowels.length) {
            return vowels.map((vowel, index) => {        
                return <div
                    key={`grid-${vowel}-${index}`}
                    className={`grid__row__${vowel}`}
                >
                    {renderKanas(vowel)}
                </div>;
            });
        }

        return null;
    }

    return (
        <div className={`grid__layout ${isOpen ? 'open' : ''}`}>
            <div className="grid__header">
                <h3>Katakana (カタカナ)</h3>
            </div>
            <div className="grid__body">
                {renderGrid()}
            </div>
        </div>
    )
}

export default Kanagrid;