import React, { useLayoutEffect, useRef, useContext, useCallback } from "react"
import gsap from "gsap"

import { StepsContext } from "../../contexts/stepsContext";

const Katakana = () => {
    const {data: {play, playSrc}, togglePlay, setSrc, changeState, toggleGameNumber} = useContext(StepsContext);

    const handleCta = useCallback((e) => {
        e.preventDefault();

        gsap.to('.fade', {
            opacity: 0,
            translateY: -20,
            duration: 0.7,
            stagger: 0.3,
            onComplete: function() {
                setSrc('');
                togglePlay(false);
                changeState(3);
                toggleGameNumber();
            }
        })
    }, [play, playSrc])

    const comp = useRef();

    // useEffect(() => {
    //     if (!play && playSrc === '') {
    //          setSrc('/fantome.mp3');
    //          togglePlay(true);
    //     }
    //  }, [play, playSrc]);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.fromTo('.fade', {
                opacity: 0,
                translateY: 20 
            }, {
                opacity: 1,
                translateY: -20,
                duration: 0.7,
                stagger: 0.3
            })
        }, comp);

        return () => ctx.revert();
    }, []);
    return <div ref={comp}>
        <div className="fade">
            <h2 className="stylized-text bigger">La katakana <span>(カタカナ)</span></h2>
        </div>
        <div className="fade">
            <p className="stylized-text">Le katakana sert à écrire des mots étrangers. Il ne change pas beaucoup en terme de prononciation.</p>
            <p className="stylized-text little">France se dit FURANSU <span>(フランス)</span></p>
            <p className="stylized-text little">Baguette se dit BAGETTO <span>(バゲット)</span></p>
            <p className="stylized-text little">Meaux se dit MO <span>(モー)</span></p>
            <p className="stylized-text little">Ile-de-France se dit IRU DO FURANSU <span>(イル・ド・フランス)</span></p>
        </div>
        <div className="fade centered">
            <a href="#" className="button" onClick={handleCta}>Ok ok, je veux jouer maintenant</a>
        </div>  
    </div>
}

export default Katakana;