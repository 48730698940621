import React, { useLayoutEffect, useRef, useContext, useEffect } from "react";
import gsap from "gsap";

import { StepsContext } from "../../contexts/stepsContext";

const Bravo = () => {
    const {data: {play, playSrc}, setSrc, togglePlay} = useContext(StepsContext);
    const comp = useRef();

    useEffect(() => {
        if (!play && playSrc === '') {
            setSrc('/queen.mp3');
            togglePlay(true);
        }
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.fromTo('.fade', {
                opacity: 0,
                translateY: 20 
            }, {
                opacity: 1,
                translateY: -20,
                duration: 0.7,
                stagger: 0.3
            });
        }, comp);

        return () => ctx.revert();
    });

    return <div ref={comp}>
        <h2 className="stylized-text bigger fade">Bravo <span>リリア</span> !!</h2>
        <p className="stylized-text fade">Tu es venu à bout de tous les mini-jeux, t'es fortiche car il faut savoir ce que j'ai en tête mouahaha 👽</p>
        <p className="stylized-text fade">De ce fait tu as gagné quelque chose dans la vraie vie. Quoi donc ? Envoie moi <span><i>rest to rent</i></span> sur Instagram pour le savoir 😉</p>
    </div>
};

export default Bravo;