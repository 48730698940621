import React, { createContext, useReducer } from "react";
import { actions, reducer } from "../reducers/stepsreducer";

export const initialState = {
    states: [1, 2, 3, 4, 5],
    currentState: 1,
    gameNumber: 1,
    showGameNumber: false,
    play: false,
    playSrc: ''
};
export const StepsContext = createContext({});
export const StepsContextProvider = ({children}) => {
    const [data, dispatch] = useReducer(reducer, initialState);
    const value = {
        data,
        changeState: (state) => dispatch({type: actions.CHANGE_STATE, currentState: state}),
        toggleGameNumber: () => dispatch({type: actions.TOGGLE_GAME_SHOW}),
        incrementGameNumber: () => dispatch({type: actions.INCREMENT_GAME_NUMBER}),
        togglePlay: (play) => dispatch({type: actions.TOGGLE_PLAY, play}),
        setSrc: (src) => dispatch({type: actions.SET_SRC, src}),
    };

    window.test = () => {
        console.log(data)
    }

    return <StepsContext.Provider value={value}>
        {children}
    </StepsContext.Provider>;
}