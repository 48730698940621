export const actions = {
    CHANGE_STATE: 'CHANGE_STATE',
    TOGGLE_GAME_SHOW: 'TOOGLE_GAME_SHOW',
    INCREMENT_GAME_NUMBER: 'INCREMENT_GAME_NUMBER',
    TOGGLE_PLAY: 'TOGGLE_PLAY',
    SET_SRC: 'SET_SRC'
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actions.CHANGE_STATE:
            return {
                ...state,
                currentState: action.currentState
            };

        case actions.TOGGLE_GAME_SHOW:
            return {
                ...state,
                showGameNumber: !state.showGameNumber
            };    
             

        case actions.INCREMENT_GAME_NUMBER:
            return {
                ...state,
                gameNumber: state.gameNumber + 1
            };
            
        case actions.TOGGLE_PLAY:
            return {
                ...state,
                play: action.play
            };
            
        case actions.SET_SRC:
            return {
                ...state,
                playSrc: action.src
            }    
        
        default: return state;
    }
};
