import React, { useCallback, useContext, useLayoutEffect, useState, useRef } from "react";
import gsap from "gsap";

import { StepsContext } from "../../contexts/stepsContext";
import Kana from "../../components/kana/kana";
import { KANAS } from "../../constants/kanas";

import './translate.css';

const Translate = () => {
    const comp = useRef();
    const {changeState, setSrc, togglePlay} = useContext(StepsContext);

    const [kanas] = useState([
        KANAS.find((k) => k.romaji === 'BU'),
        KANAS.find((k) => k.romaji === 'RU'),
        KANAS.find((k) => k.romaji === 'TA'),
        KANAS.find((k) => k.romaji === 'NI'),
        KANAS.find((k) => k.romaji === 'U'),
    ]);

    const [word] = useState('BRETAGNE');
    const [value, setValue] = useState('');

    const handleInput = useCallback((e) => {
        setValue(e.target.value.toUpperCase().trim());
    }, [value, setValue]);

    const handle = useCallback((e) => {
        e.preventDefault();

        if (value !== word) {
            setSrc('/nani.mp3');
            togglePlay(true);

            gsap.timeline().to('.validate', {translateX: -2, duration: 0.2})
                .to('.validate', {translateX: 2, duration: 0.1})
                .to('.validate', {translateX: -2, duration: 0.1})
                .to('.validate', {translateX: 2, duration: 0.1})
                .to('.validate', {translateX: -2, duration: 0.1})
                .to('.validate', {translateX: 2, duration: 0.1})
                .to('.validate', {translateX: -2, duration: 0.1})
                .to('.validate', {
                    translateX: 2,
                    duration: 0.1,
                    onComplete: () => {
                        setSrc('');
                        togglePlay(false); 
                    }
                });   
        } else {
            setSrc('/dattebayo.mp3');
            togglePlay(true);

            gsap.timeline().to('.validate', {
                translateY: 10,
                duration: 0.15
            })
            .to('.validate', {
                translateY: 0,
                onComplete: () => {
                    gsap.timeline().fromTo('.fade', {
                        translateY: 0,
                        opacity: 1
                    }, {
                        translateY: -20,
                        opacity: 0,
                        duration: 0.7,
                        stagger: 0.3,
                        onComplete: () => {
                            setSrc('');
                            togglePlay(false);
                            changeState(6);
                        }
                    });
                }
            });
        }
    }, [value, word]);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.fromTo('.fade', {
                opacity: 0,
                translateY: 20 
            }, {
                opacity: 1,
                translateY: -20,
                duration: 0.7,
                stagger: 0.3
            });
        }, comp);

        return () => ctx.revert();
    }, []);

    const renderKanas = useCallback(() => {
        return kanas.map((kana) => {
            return <div key={`kana-${kana.romaji}`}>
                <Kana kana={kana} />
            </div>
        })
    });

    return <div ref={comp}>
        <div>
            <h2 className="stylized-text bigger fade">Translate</h2>
            <p className="stylized-text fade">A ton avis, que veut dire ce mot ? 😊</p>
        </div>
        <div className="translate__kana fade">
            {renderKanas()}
        </div>
        <div className="translate__input-layout fade">
            <input type="text" onChange={handleInput} />
        </div>    
        <div className="centered fade">
            <a href="#" className="button validate" onClick={handle}>Valider</a>
        </div>
    </div>
};

export default Translate;