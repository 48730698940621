import React, { useContext, useEffect } from "react";
import { StepsContext } from "../../contexts/stepsContext";

import Hello from "../../features/hello/hello";
import Katanaka from "../../features/katakana/katakana";
import Rebus from "../../features/rebus/rebus";
import MakeWorld from "../../features/makeWorld/makeWorld";
import Translate from "../../features/translate/translate";
import Bravo from "../../features/bravo/bravo";

import './main.css'
import NumberStep from "../numberStep/numberStep";
import useAudio from "../../customHooks/useAudio/useAudio";

const Main = () => {
    const {data: {play, playSrc, showGameNumber, gameNumber, currentState}} = useContext(StepsContext);
    const [audioRef, toggle, setAudioRef] = useAudio();

    useEffect(() => {
        if (play && playSrc && audioRef) {
            toggle(true);
        }

        if (!play || playSrc === '') {
            toggle(false);
        }
    }, [play, playSrc, audioRef]);

    return <div className="main">
        <audio ref={(el) => setAudioRef(el)} src={playSrc ? playSrc : null} />
        {showGameNumber && <NumberStep number={gameNumber} />}
        {(!showGameNumber && currentState === 1) && <Hello />}
        {(!showGameNumber && currentState === 2) && <Katanaka />}
        {(!showGameNumber && currentState === 3) && <Rebus />}
        {(!showGameNumber && currentState === 4) && <MakeWorld />}
        {(!showGameNumber && currentState === 5) && <Translate />}
        {(!showGameNumber && currentState === 6) && <Bravo />}
    </div>;
};

export default Main;