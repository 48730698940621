import React from 'react';
import './kana.css';

const Kana = ({kana, mode, inArray, cta}) => {
    if (!kana) {
        return null;
    }

    const handle = () => {
        if (cta) {
            console.log('will cta', cta);
            cta(kana);
        }
    }

    return (
        <div className={`kana ${inArray ? 'array' : ''}`} onClick={handle}>
            <div className='kana__symbol'>{mode === 'hiragana' ? kana.hiragana : kana.katakana}</div>
            <div className='kana__romaji'>{kana.romaji}</div>
        </div>
    );
};

export default Kana;